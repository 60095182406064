import $ from 'blingblingjs';

const [controlPrev] = $('.js-scoreboard-prev');
const [controlNext] = $('.js-scoreboard-next');
const [slider] = $('.js-scoreboard-slider');
let position = 0;
const moveDistance = 300;

const move = (distance) => {
    slider.scrollLeft = moveDistance * distance;
};

const handleClickOnBtn = (e, value) => {
    // stop default action
    e.preventDefault();
    // set new position
    position += value;
    // move slider
    move(position);
};

const handleScroll = () => {
    // beginning?
    if (slider.scrollLeft > 0) {
        controlPrev.disabled = false;
    } else {
        controlPrev.disabled = true;
    }

    // end ?
    if (slider.scrollLeft + slider.clientWidth >= slider.scrollWidth) {
        controlNext.disabled = true;
    } else {
        controlNext.disabled = false;
    }
};

if (controlPrev) controlPrev.on('click', (e) => handleClickOnBtn(e, -1));
if (controlNext) controlNext.on('click', (e) => handleClickOnBtn(e, 1));
if (slider) slider.on('scroll', handleScroll);
